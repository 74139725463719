import React from 'react'
import './hero.css'
import arrow from '..//../Images/white-arrow.png'
import { Link } from 'react-router-dom'

const hero = () => {
  return (
    <div className='hero'>
        <div className='hero-left'>
            <h1>Get your Projects Done Remotely by Qualified Freelancers</h1>
           
            <div className='hero-popular'>
            <Link to='/post-job' className='Link'> <button className='hero-btn'>Post a Project <img src={arrow} alt=''/></button></Link>
            </div>
        </div>
        <div className='hero-right'>
            
        </div>
    </div>
  )
}

export default hero