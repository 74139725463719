// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCsfRtnMu86vRhDAJrufYvnNe1vnzoW2Lk",
    authDomain: "job-board-10306.firebaseapp.com",
    projectId: "job-board-10306",
    storageBucket: "job-board-10306.appspot.com",
    messagingSenderId: "52258903017",
    appId: "1:52258903017:web:c43b8bf511d52c80f0f178"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);


