// src/components/JobForm.js
import React, { useState } from "react";
import { db, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import "./JobForm.css";
import { Link, useNavigate } from "react-router-dom";
import Login from './Login'

const JobForm = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const [budget, setBudget] = useState("");
  const [payment, setPayment] = useState("Pending Payment");
  const navigate = useNavigate();
  const user =auth.currentUser;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        await addDoc(collection(db, "jobs"), {
          title,
          description,
          deadline,
          budget,
          payment,
          userId: user.uid,
          createdAt: new Date(),
        });
        setTitle("");
        setDescription("");
        setDeadline("");
        setBudget("");
        setPayment("Pending Payment");
        navigate("/dashboard");
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("You need to be logged in to post a job.");
    }
  };

  return (
    <>
      {user ? (
            <form onSubmit={handleSubmit} className="job-form">
            <div className="dashHeader">
              <h2>Post a Job</h2>
              <Link to="/dashboard">
                <button className="addBtn">Submitted Jobs</button>
              </Link>
            </div>
              <label>Job Title</label>
              <input
                type="text"
                placeholder="e.g Graphic Design Work"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <label>Job Description</label>
              <textarea
                rows={5}
                placeholder="Give project description/instructions in details"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
              <label>Deadline</label>
              <input
                type="date"
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                required
              />
              <label>Your Budget</label>
              <input
                type="number"
                placeholder="Give estimated budget in $"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                required
              />
              <button type="submit">Submit Job</button>
            </form>
          ) : (
            <>
              <Login/>
            </>
          )}
      
    </>
  );
};

export default JobForm;
