// src/App.js
import React from 'react';
import { Route, Routes} from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import JobForm from './components/JobForm';
import JobList from './components/JobList';
import Home from "./components/Home/Home.js";
import './App.css';
import Steps from "./components/steps/steps.js";
import Contact from "./components/contact/contact.js";
import Navbar from "./components/navbar/Navbar.js";
import Footer from "./components/footer/Footer.js";

const App = () => {
 
  return (
  
      <div className="App">
        <Navbar/>  
        <Routes>
        <Route path="/" element={<Home/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/post-job" element={<JobForm/>} />
          <Route path="/dashboard" element={<JobList/>} />
          <Route path="/steps" element={<Steps />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
         </Routes>
         <Footer/>
      </div>
    
  );
};

export default App;
