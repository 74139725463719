import React from "react";
import "./Footer.css";

function Footer() {
  return (
    
    <div className="footer">
      <div className="container">
        <div className="top">
          <div className="item">
            <h2>About us</h2>
            <span>Home</span>
            <span>Contact Us</span>
            <span>About us</span>
            <span>Privacy Policy</span>
            <span>Terms of use</span>
            <span>Blog</span>
            <span>FAQs</span>
          </div>
        
          <div className="item">
            <h2>We Have</h2>
            <span>Designers</span>
            <span>Marketers</span>
            <span>Translators</span>
            <span>Web Developers</span>
            <span>Writers</span>
            <span>Virtual Assistants</span>
            <span>Affiliates</span>
            <span>All categories</span>
           
          </div>
          
        </div>
        <hr />
        <div className="bottom">
        <span>©  24 Tasks International Ltd. 2025</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
