// src/components/JobList.js
import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './JobList.css';
import { Link } from 'react-router-dom';

const JobList = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const user = auth.currentUser;
      if (user) {
        const q = query(collection(db, 'jobs'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const jobList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setJobs(jobList);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="job-list">
      <div className='dashHeader'>
      <h2>Posted Jobs</h2>
      <Link to='https://payment.24task.net/'><button className='payBtn'>Pay for Order</button></Link>
      <Link to='/post-job'><button className='addBtn'>Add Order</button></Link>
     
      </div>
      
      <ul>
        {jobs.map(job => (
          <li key={job.id} className="job-item">
            <h3><span className='jobTitles'>Job Title:</span> <br/> {job.title}</h3>
            <p> <span className='jobTitles'>Job Description:</span> <br/>{job.description}</p>
            <p> <span className='jobTitles'>Deadline:</span> {job.deadline}</p>
            <p> <span className='jobTitles'>Budget:</span> ${job.budget}</p> 
            <h4><span className='jobTitles'>Status: </span>{job.payment}</h4>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JobList;
