import React , { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { auth } from '../../firebase';


import "./Navbar.css";

function Navbar() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);
  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">
          <Link className="link" to="/">
            <span className="text">24 Tasks |  <span className="pillar-text"> Reliable & Affordable.</span> </span>
          </Link>
          <span className="dot"></span>
        </div>
        <div className="links">
          <div className="changeDisplay">
          <Link to="/post-job" className="link">
            <span>Submit a Task</span>
          </Link>
          </div>
          
          <Link to="/steps" className="link">
            <span>How it Works</span>
          </Link>
          
          <div >
          {user ? (
            <>
              <button onClick={() => auth.signOut()} className="logoutBtn">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login"><button className="loginBtn">Login</button></Link>
            </>
          )}
          </div>
        </div>
      </div>
      <div className="menu">
        <Link className="link menuLink" to="">
          Graphics & Design
        </Link>
        <Link className="link menuLink" to="">
          Digital Marketing
        </Link>
        <Link className="link menuLink" to="">
          Writing & Translation
        </Link>
        <Link className="link menuLink" to="">
          Web Developers
        </Link>
        <Link className="link menuLink" to="">
          Programming & Tech
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
