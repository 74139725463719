import React from 'react'
import './steps.css'
import { Link } from 'react-router-dom'
import worry from '../../Images/worry.png'
import steps_img from '../../Images/steps-img.png'

function how() {
  return (
    <div className='steps'>
      <h1>How It Works</h1>
      <div className='steps-row'>
        <div className='steps-col'>
            <h2>1. Post Job / Task</h2>
            <p>Register an Employer's Account or Login if you already have an account and 
              Post your Job by filling the job form provided.
              </p>
              <h2>2. Place your deposit</h2>
            <p>After posting your Job on the platform , you are required to deposit an amount with us for your Work to be assigned. You can chat with Customer support to determine how much your project will cost. 
              </p>
              <h2>3. Approve</h2>
            <p>Once you make a deposit, your project will be worked on and will be uploaded to your portal or Email . 
              Receive and review your project. If the project has met all your needs then you can approve it and Mark the order as Complete. 
              
              </p>
              <Link to='/signup'><button className='getstarted-btn'>Get Started</button></Link>
        </div>
        <div className='steps-col'>
          <img src={steps_img} alt=''/>
        </div>
      </div>
      <hr></hr>
      <div className='quote-banner'>
        <div className='quote-col'>
          <h2><span>Not sure?</span> Post a job and get a free quote for your project from our Support or our team of freelancers.
          </h2>
          <Link to='/quote'><button className='getstarted-btn'>Get a Free Quote</button></Link>
        </div>
        <div className='banner-image'>
            <img src={worry} alt='man'/>
        </div>
      </div>

    </div>
  )
}

export default how