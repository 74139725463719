import React from 'react'
import './contact.css'
import msg_icon from '../../Images/msg-icon.png'
import mail_icon from '../../Images/mail-icon.png'
import phone_icon from '../../Images/phone-icon.png'
import white_arrow from '../../Images/white-arrow.png'


const Contact = () => {
    const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "cac0d1f2-0e7a-48bb-b938-7493f58500e0");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Email sent Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
      event.target.reset();
    }
  };
  return (
    <div>
    <div className='title'>
        
        <h2>Get in Touch</h2>
      </div>
    <div className='contact container'>
        <div className='contact-col'>
            <h3>Send us a message <img src= {msg_icon} alt=''/></h3>
            <p>Feel free to reach out through contact form or find our contact information below.
                Your feedback, questions and suggestions are important to us as we strive to provide exceptional 
                service to our clients.
            </p>
            <ul>
                <li> <img src={mail_icon} alt=''/>support@24task.net</li>
                <li> <img src={phone_icon} alt=''/>+1 00 111 222</li>
                <li>Privacy and security of your details is our first priority. </li>
            </ul>
        </div>
        <div className='contact-col'>
            <form onSubmit={onSubmit}>
                <label>Your name</label>
                <input type='text' name='name' placeholder='Enter your name' required/>
                <label>Email</label>
                <input type='email' name='email' placeholder='Enter your email address' required/>
                <label>Write your message here</label>
                <textarea name='message' rows="6" placeholder='Enter your message' required/>
                <button type='submit' className='dark-btn'>Submit now <img src={white_arrow} alt=''/></button>
            </form>
            <span>{result}</span>
        </div>
        
    </div>
    </div>
  )
}

export default Contact